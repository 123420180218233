import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'

const LogIndex = ({
	data: {
		allMarkdownRemark: { edges },
	},
}) => {
	const logs = edges
		.filter(edge => edge.node.fields.isLog)
		.filter(edge => !edge.node.frontmatter.draft)
		.map(edge => (
			<div key={edge.node.id} className="post-listing">
				<Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
				<small>{edge.node.frontmatter.date}</small>
				<span>{edge.node.excerpt}</span>
			</div>
		))

	return (
		<Layout title="Logs">
			<div className="article">
				<h1>Logs</h1>
				<section>
					<p>
						Logs are short, quick 5-min blog posts. The main idea behind a Log is to get my ideas out there at a higher
						frequency so that it has a chance of helping others.
					</p>
				</section>
				<div>{logs}</div>
			</div>
		</Layout>
	)
}

export default LogIndex

export const pageQuery = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						title
						draft
						date(formatString: "MMMM DD, YYYY")
					}
					fields {
						slug
						isLog
					}
				}
			}
		}
	}
`

import React from 'react'
import Header from '../components/header'
import './../styles/styles.scss'
import Root from './root'

export default ({ children, title }) => (
	<Root title={title}>
		<div>
			<Header />
			<main className="content">{children}</main>
			{/* <Footer /> */}
		</div>
	</Root>
)

import React from 'react'
import { Link } from 'gatsby'

const Header = () => (
	<nav className="header">
		<Link to="/">
			<span role="img" aria-label="house">
				🏠
			</span>{' '}
			Home
		</Link>
		<Link to="/blog">
			<span role="img" aria-label="letter">
				📝
			</span>{' '}
			Blog
		</Link>
		<Link to="/letters">
			<span role="img" aria-label="love letter">
				💌
			</span>{' '}
			Newsletter
		</Link>
	</nav>
)

export default Header
